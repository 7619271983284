<template>
    <div class="relative h-screen overflow-y-scroll bg-green-400">
        <img 
            src="@/assets/images/pattern.svg"
            class="absolute inset-0"
        />

        <div class="relative h-full">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Fullscreen'
    }
</script>