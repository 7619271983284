<template>
    <FullscreenLayout>
        <div class="px-6 py-4 flex min-h-screen ">
            <div class="my-auto w-full">
                <form
                    class="mx-auto block w-full max-w-sm px-6 py-8 bg-white rounded-2xl shadow-xl"
                    @submit.prevent="login"
                >
                    <div>
                        <img 
                            src="@/assets/images/logo.svg"
                            class="mx-auto h-32"
                        />
                    </div>

                    <label class="mt-8 block">
                        <p class="">Email</p>
                        <input 
                            type="email"
                            v-model="form.email"
                            required
                            placeholder="Enter your email"
                            class="mt-1 w-full form-input"
                        />
                    </label>

                    <label class="mt-3 block">
                        <p class="">Password</p>
                        <input 
                            type="password"
                            v-model="form.password"
                            required
                            placeholder="Enter your password"
                            class="mt-1 w-full form-input"
                        />
                    </label>

                    <button
                        type="submit"
                        class="mt-8 px-6 py-2 w-full text-white font-medium bg-indigo-500 rounded-lg"
                        :class="{ 'opacity-50': loading }"
                        :disabled="loading"
                    >
                        Login
                    </button>
                </form>
            </div>
        </div>
    </FullscreenLayout>
</template>

<script>
    import { computed, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import PreLogin from '@/api/endpoints/PreLogin.js';
    import Auth from '@/api/endpoints/Auth.js';

    import FullscreenLayout from '@/layouts/fullscreen';

    import usePromise from '@/composables/use-promise.js';
    import { notify } from '@/components/notifications';
    
    import { state, actions } from '@/store';

    export default {
        name: 'Login',
        components: {
            FullscreenLayout
        },
        setup() {
            const router = useRouter();
            const forward = computed(() => state.forward);
            
            const form = ref({
                email: '',
                password: ''
            });

            function next() {
                const route = forward.value ? { ...forward.value } : { name: 'Home' };
                router.push(route);
            }

            const {
                loading,
                use: login
            } = usePromise(async () => {
                try {       
                    const { backSubdomain, streamSubdomain } = await PreLogin.find({
                        email: form.value?.email,
                    });     

                    actions.setBackSubdomain(backSubdomain);
                    actions.setStreamSubdomain(streamSubdomain);

                    const token = await Auth.login(form.value);
                    actions.setToken(token);
                
                    const users = await Auth.me();
                    actions.setUsers(users); 

                    if (users.length === 1) {
                        actions.setUser(users[0]);
                        router.push({ name: 'Home' });
                    } else {
                        router.push({ name: 'UserSelector' });
                    }
                } catch (error) {
                    notify({
                        title: 'Login error',
                        text: 'Invalid credentials'
                    });
                }
            });


            return {
                form,
                loading,
                login,
                forward,
                next
            };
        }
    }
</script>