import Endpoint from '../Endpoint'

class PreLogin extends Endpoint {
	baseUrl() {
		return process.env.VUE_APP_PRE_LOGIN_URL
	}

	find(form) {
		return this.request({
			method: 'POST',
			data: form,
		})
	}
}

export default new PreLogin()
